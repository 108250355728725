<template>
  <b-card>
    <div slot="header">
      {{ title }} 
      <span style="float: right" v-show="source == 'spotify'">
        Source: Spotify <i class="fa fa-spotify"></i>
      </span>
      <span style="float: right" v-show="subTitle">
        {{ subTitle }}
      </span>
    </div>

    <div v-show="trendsLoading">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    
    <div v-show="loadingError">
      An error occurred while loading.
    </div>
    
    <div v-show="countTotal == 0">
      No data available
    </div>

    <b-table striped small :fields="fields" :items="items" v-show="countTotal > 0">
      <template v-slot:head(title)="">
        {{ columnTitle }}
      </template>

      <template v-slot:cell(title)="row">
        <a v-if="canSelect == true" href="#" @click.prevent="$emit('item-click', {type: aggName, item: row.item})">{{ row.item.title }}</a>
        <span v-if="canSelect == false">{{ row.item.title }}</span>
      </template>

      <template v-slot:cell(count)="row">
        {{ row.item.count | numberFormat }}
      </template>

      <template v-slot:cell(percentage)="row">
        {{ row.item.percentage | numberFormat }} %
      </template>
    </b-table>
  </b-card>
</template>

<script>
  import RoyaltyApi from '@/mixins/RoyaltyApi'
  
  export default {
    name: 'TrendList',
    mixins: [RoyaltyApi],
    data () {
      return {
        loadingError: false,
        items: [],
        fields: [
        ], 
        trendsLoading: false,
        countTotal: null,
        
      }
    },
    props: {
      title: String,
      columnTitle: String,
      trendFilter: Object,
      aggName: String,
      perPage: {type: Number, default: 5},
      source: {type: String, default: ''},
      subTitle: {type: String, default: ''},
      showCount: {type: Boolean, default: true},
      canSelect: {type: Boolean, default: false},
    },
    methods: {
      refreshTrends () {
        if (this.trendsLoading) return;
        this.items = []
        this.trendsLoading = true
        this.loadingError = false

        this.fields = []
        this.fields.push({ key: 'title' })
        this.fields.push({ key: 'percentage', class: 'text-right' })
        if (this.showCount) {
          this.fields.push({ key: 'count', class: 'text-right' })
        }

        this.getTrendData(this.trendFilter, this.aggName, this.perPage)
          .then(response => {
            this.countTotal = response.countTotal;
            this.items = []
            response.buckets.forEach((item) => {
              this.items.push({
                key: item.key,
                title: item.key_as_string || item.key,
                count: item.doc_count,
                percentage: item.percentage, 
              })
            })
          }).catch(() => {
            this.loadingError = true
            this.trendsLoading = false
          }).finally(() => {
            this.trendsLoading = false
          })
      },
    },
    watch: {
      trendFilter: {
        handler: 'refreshTrends', 
        deep: true
      },
      perPage: {
        handler: 'refreshTrends', 
        deep: true
      },
    },
    mounted () {
      this.refreshTrends()
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
