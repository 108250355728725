export default {
    methods: {
      async waitForJob(response) {
        if (response.data.status != 'delayed') {
          return response.data
        }

        const jobId = response.data.jobId

        for (;;) {
          response = await this.$http.get('job/' + jobId)

          if (response.data.status == 'completed') {
            this.statusMsg = null
            // console.log('done', response.data.queueNr)
            return response.data.data
          }

          if (response.data.status == 'waiting') {

            if (response.data.lastQueueNr && response.data.queueNr) {
              // console.log('waiting', response.data.queueNr, response.data.lastQueueNr)
              this.statusMsg = 'Waiting in line (' + (response.data.queueNr - response.data.lastQueueNr) +')'
            } else {
              this.statusMsg = 'Waiting in line'
            }
          } else if (response.data.status == 'running') {
            this.statusMsg = 'Query is running'
          }
          
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      },
      getTrendData(filter, aggName, perPage) {
        return new Promise((resolve, reject) => {
            if (filter.timerange == 'custom' && (
                filter.dateFrom == '' || filter.dateTo == ''))
            return reject();

            this.$http
                .post('analytics/trend-data', {
                    filter: filter,
                    aggName: aggName,
                    perPage: perPage,
                })
                .then(response => {
                    var data = response.data
                    resolve(data)
                })
                .catch(response => {
                    reject(response.data)
                })

        })
      },
      getRoyaltyAnalyticsData(filter, aggName, target, perPage, sortDirection) {
        return new Promise((resolve, reject) => {
            if (filter.timerange == 'custom' && (
                filter.dateFrom == '' || filter.dateTo == ''))
            return reject();

            this.$http
                .post('analytics/royalty-data', {
                    filter: filter,
                    aggName: aggName,
                    target: target,
                    perPage: perPage,
                    sortDirection: sortDirection,
                })
                .then(response => {
                    var data = response.data
                    resolve(data)
                })
        })
      },
      getCountryList() {
        return new Promise((resolve) => {
            this.$http
                .post('getCountryList', {

                })
                .then(response => {
                    var data = response.data.result
                    resolve(data)
                })
        })
      },
      getTaskList() {
        return new Promise((resolve) => {
            this.$http
                .post('getTaskList', {

                })
                .then(response => {
                    var data = response.data.result
                    resolve(data)
                })
        })
      },
      getStatementReports(accountingPeriod = null) {
        return new Promise((resolve) => {
            this.$http
                .get('getStatementReports', {params: {
                  accountingPeriod: accountingPeriod
                }})
                .then(response => {
                    var data = response.data.result
                    resolve(data)
                })
        })
      }
    }
  };